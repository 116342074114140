<div #barrasup>

  <p-menubar [model]="items" [hidden]="!variablesGlobales.datosUsuario" class="step_050">

    <div>
      <table style="border-collapse: collapse;">
        <tr>
          <td *ngIf="variablesGlobales?.datosUsuario?.demo">
            <span class="p-tag p-tag-danger p-badge-lg" style="margin-right: 10px; cursor: pointer;"
            pTooltip=" Actualmente está utilizando el modo DEMOSTRACIÓN. Los certificados obtenidos no tendrán valor. Si desea obtener una
            cuenta NORMAL realice una recarga de crédito." (click)="this.router.navigateByUrl('stripe');"
            tooltipPosition="bottom" showDelay="1000">DEMO</span>
          </td>
          <td>
            <button type="button" pButton pRipple class="p-button-warning p-button-rounded step_000"
              (click)="btnAyuda()" icon="pi pi-compass"
              pTooltip="Pulse aquí si desea realizar un breve tour por las características de la pantalla"
              tooltipPosition="bottom" showDelay="1000"></button>
          </td>
          <td>
            <img [src]="variablesGlobales.logotipo"
              style="vertical-align: middle; max-height: 30px; width: auto; padding-left: 10px; padding-right:10px;" />
          </td>
        </tr>
      </table>
    </div>

  </p-menubar>

</div>
