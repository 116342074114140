import { Injectable } from '@angular/core';
import { ShepherdService } from 'angular-shepherd';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';

import { StepsLogin } from './steps/steps.login';
import { StepsInicio } from './steps/steps.inicio';
import { StepsPerfil } from './steps/steps.perfil';
import { StepsConfiguracion } from './steps/steps.configuracion';
import { StepsFacturas } from './steps/steps.facturas';
import { StepsTokens } from './steps/steps.tokens';
import { StepsContactos } from './steps/steps.contactos';
import { StepsPlantillasListar } from './steps/steps.plantillas-listar';
import { StepsPlantillasCrearEditar } from './steps/steps.plantillas-crear-editar';
import { StepsEnviosNuevo } from './steps/steps.envios-nuevo';
import { StepsEnviosConsultar } from './steps/steps.envios-consultar';
import { StepsEnviosVer } from './steps/steps.envios-ver';
import { StepsSellarWeb } from './steps/steps.sellar-web';
import { StepsSellarFichero } from './steps/steps.sellar-fichero';
import { StepsSellarNavegacion } from './steps/steps.sellar-navegacion';
import { StepsSellosDeTiempoConsultar } from './steps/steps.sellos-de-tiempo-consultar';
import { StepsStripe } from './steps/steps.stripe';
import { StepsSoporte } from './steps/steps.soporte';
import { StepsApi } from './steps/steps.api';

@Injectable()
export class TourService {

    constructor(
        private messageService: MessageService,
        private stepsLogin: StepsLogin,
        private stepsInicio: StepsInicio,
        private stepsPerfil: StepsPerfil,
        private stepsEnviosNuevo: StepsEnviosNuevo,
        private stepsConfiguracion: StepsConfiguracion,
        private stepsFacturas: StepsFacturas,
        private stepsTokens: StepsTokens,
        private stepsContactos: StepsContactos,
        private stepsPlantillasListar: StepsPlantillasListar,
        private stepsPlantillasCrearEditar: StepsPlantillasCrearEditar,
        private stepsEnviosConsultar: StepsEnviosConsultar,
        private stepsEnviosVer: StepsEnviosVer,
        private stepsSellarWeb: StepsSellarWeb,
        private stepsSellarFichero: StepsSellarFichero,
        private stepsSellarNavegacion: StepsSellarNavegacion,
        private stepsSellosDeTiempoConsultar: StepsSellosDeTiempoConsultar,
        private stepsStripe: StepsStripe,
        private stepsSoporte: StepsSoporte,
        private stepsApi: StepsApi,

        private router: Router,
        private shepherdService: ShepherdService,
    ) {
        this.shepherdService.modal = true;
        this.shepherdService.confirmCancel = false;
        this.shepherdService.defaultStepOptions = {
            arrow: true,
            cancelIcon: {
                enabled: false
            },
            canClickTarget: false,
            modalOverlayOpeningPadding: 2,
            modalOverlayOpeningRadius: 2,
            scrollTo: { behavior: 'smooth', block: 'center' },
            popperOptions: {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 15],
                        },
                    },
                ],
            }
        };

    }

    mostrarTour() {

        if (this.router.url === '/' || this.router.url.includes('login')) {
            this.shepherdService.addSteps(this.stepsLogin.crear());
        } else if (this.router.url.includes('inicio')) {
            this.shepherdService.addSteps(this.stepsInicio.crear());
        } else if (this.router.url.includes('perfil')) {
            this.shepherdService.addSteps(this.stepsPerfil.crear());
        } else if (this.router.url.includes('configuracion')) {
            this.shepherdService.addSteps(this.stepsConfiguracion.crear());
        } else if (this.router.url.includes('facturas')) {
            this.shepherdService.addSteps(this.stepsFacturas.crear());
        } else if (this.router.url.includes('tokens')) {
            this.shepherdService.addSteps(this.stepsTokens.crear());
        } else if (this.router.url.includes('contactos')) {
            this.shepherdService.addSteps(this.stepsContactos.crear());
        } else if (this.router.url.includes('plantillas-listar')) {
            this.shepherdService.addSteps(this.stepsPlantillasListar.crear());
        } else if (this.router.url.includes('plantillas-crear-editar')) {
            this.shepherdService.addSteps(this.stepsPlantillasCrearEditar.crear());
        } else if (this.router.url.includes('envios-nuevo')) {
            // Si cancelamos el Tour, tenemos que dejar la página como al principio
            this.shepherdService.onTourFinish = (completeOrCancel: string) => this.stepsEnviosNuevo.porDefecto(completeOrCancel);
            this.shepherdService.addSteps(this.stepsEnviosNuevo.crear());
        } else if (this.router.url.includes('envios-consultar')) {
            this.shepherdService.addSteps(this.stepsEnviosConsultar.crear());
        } else if (this.router.url.includes('envios-ver')) {
            this.shepherdService.addSteps(this.stepsEnviosVer.crear());
        } else if (this.router.url.includes('sellar-web')) {
            this.shepherdService.onTourFinish = (completeOrCancel: string) => this.stepsSellarWeb.porDefecto(completeOrCancel);
            this.shepherdService.addSteps(this.stepsSellarWeb.crear());
        } else if (this.router.url.includes('sellar-fichero')) {
            this.shepherdService.onTourFinish = (completeOrCancel: string) => this.stepsSellarFichero.porDefecto(completeOrCancel);
            this.shepherdService.addSteps(this.stepsSellarFichero.crear());
        } else if (this.router.url.includes('sellar-navegacion')) {
            this.shepherdService.onTourFinish = (completeOrCancel: string) => this.stepsSellarNavegacion.porDefecto(completeOrCancel);
            this.shepherdService.addSteps(this.stepsSellarNavegacion.crear());
        } else if (this.router.url.includes('sellodetiempo-consultar')) {
            this.shepherdService.addSteps(this.stepsSellosDeTiempoConsultar.crear());
        } else if (this.router.url.includes('stripe')) {
            this.shepherdService.addSteps(this.stepsStripe.crear());
        } else if (this.router.url.includes('soporte')) {
            this.shepherdService.addSteps(this.stepsSoporte.crear());
        } else if (this.router.url.includes('api')) {
            this.shepherdService.addSteps(this.stepsApi.crear());

        } else {

            this.messageService.clear();
            this.messageService.add({
                severity: 'error',
                summary: '¡ERROR!',
                detail: 'La ayuda para esta página aún no está disponible.  Disculpe las molestias.',
                life: 10000,
                data: {
                    icono: 'pi pi-times-circle'
                }
            });

            return;
        }

        this.shepherdService.start();

    }

}
