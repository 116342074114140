import { buttonsFirst, buttons, buttonsLast } from '../botones';
import { Injectable } from '@angular/core';

@Injectable()
export class StepsPlantillasCrearEditar {

    constructor(

    ) {
        this.crear();
    }

    crear() {

        return [

            {
                attachTo: {
                    on: 'center'
                },
                title: 'CREAR/MODIFICAR/VER PLANTILLA',
                text: `
            En esta página puede crear, modificar o editar las plantillas que utilizará en sus envíos de documentos para su ENTREGA y FIRMA CERTIFICADA.
            La pantalla está compuesta de tres partes diferenciadas.
        `,
                buttons: buttonsFirst,
            },
            {
                attachTo: {
                    element: '.step_100',
                    on: 'auto'
                },
                title: 'SECCIÓN 1: GENERAL',
                text: `
            Para empezar debemos asignar un nombre y elegir un color que se utilizará en la plantilla.  Para ello tenemos los siguientes dos campos en el formulario.
        `,
                buttons: buttons,
            },
            {
                attachTo: {
                    element: '.step_200',
                    on: 'auto'
                },
                title: 'NOMBRE',
                text: `
            Escriba aquí el nombre que quiera para identificar la plantilla.  Le será de utilidad para localizarla más tarde en el listado de plantillas.
        `,
                buttons: buttons,
            },
            {
                attachTo: {
                    element: '.step_300',
                    on: 'auto'
                },
                title: 'COLOR',
                text: `
            Elija el color que más se ajusta a la imagen de su empresa para ofrecer una imagen corporativa adecuada.
        `,
                buttons: buttons,
            },
            {
                attachTo: {
                    element: '.step_400',
                    on: 'auto'
                },
                title: 'SECCION 2: CORREO ELECTRÓNICO',
                text: `
            Aquí puede personalizar el email de notificación en cual se envía al firmante al inicio del proceso de FIRMA ELECTRÓNICA CERTIFICADA.
        `,
                buttons: buttons,
            },
            {
                attachTo: {
                    element: '.step_500',
                    on: 'auto'
                },
                title: 'ASUNTO DEL CORREO',
                text: `
             Escriba aquí el ASUNTO o TÍTULO del correo electrónico.
        `,
                buttons: buttons,
            },
            {
                attachTo: {
                    element: '.step_600',
                    on: 'auto'
                },
                title: 'NOMBRE DEL REMITENTE',
                text: `
             El nombre del remitente que aparecerá en el correo electrónico.
        `,
                buttons: buttons,
            },
            {
                attachTo: {
                    element: '.step_700',
                    on: 'auto'
                },
                title: 'CABECERA DEL CORREO',
                text: `
            En el cuerpo del correo puede personalizar la parte superior o cabecera del mismo.  Aparece con el color de fondo elegido anteriormente.
            Normalmente se suele utilizar para mostrar el nombre de su empresa u organización, aunque puede mostrar el texto que desee.
        `,
                buttons: buttons,
            },
            {
                attachTo: {
                    element: '.step_800',
                    on: 'auto'
                },
                title: 'CUERPO DEL CORREO',
                text: `
            Aquí puede editar la parte central del cuerpo del correo.  Generalmente se utilizar para describir el proceso que el remitente realizará al pulsar sobre el botón inferior.  
        `,
                buttons: buttons,
            },
            {
                attachTo: {
                    element: '.step_900',
                    on: 'auto'
                },
                title: 'TEXTO DEL BOTÓN DE ACCIÓN',
                text: `
            Escriba aquí el TEXTO DEL BOTÓN DE ACCIÓN.  Normalmente será algo similar a "VER LOS DOCUMENTOS" aunque puede elegir el texto que mejor se adapte a sus necesidades. 
        `,
                buttons: buttons,
            },
            {
                attachTo: {
                    element: '.step_1000',
                    on: 'auto'
                },
                title: 'PIE CORREO',
                text: `
            Y para finalizar esta sección, escriba el PIE DEL CORREO.  Generalmente se utiliza para mostrar un teléfono de contacto o una persona de contacto.  Igualmente puede personalizarlo a su gusto.
        `,
                buttons: buttons,
            },
            {
                attachTo: {
                    element: '.step_1100',
                    on: 'auto'
                },
                title: 'PREVISUALIZACIÓN',
                text: `
            Durante todo el proceso podrá PREVISUALIZAR el resultado en esta zona.
        `,
                buttons: buttons,
            },
            {
                attachTo: {
                    element: '.step_1200',
                    on: 'auto'
                },
                title: 'SECCIÓN 3: PÁGINA DE FIRMA',
                text: `
            Una vez que el destinatario del correo anterior pulse sobre el botón de acción será redirigido a la página de FIRMA DEL DOCUMENTO.  
            En ella se mostrarán los siguientes campos junto al documento PDF que enviará para su FIRMA.
        `,
                buttons: buttons,
            },
            {
                attachTo: {
                    element: '.step_1300',
                    on: 'auto'
                },
                title: 'CABECERA DE PÁGINA',
                text: `
            Escriba la CABECERA o parte superior de la página.  Puede ser el nombre de su empresa o el texto que seleccione.  
            Normalmente se utilizar para mostrar el nombre de su empresa u organización.  Puede personalizarlo según su criterio.
        `,
                buttons: buttons,
            },
            {
                attachTo: {
                    element: '.step_1400',
                    on: 'auto'
                },
                title: 'CUERPO DE LA PAGINA',
                text: `
            Puede diseñar el cuerpo de la página de firma del documento.  Normalmente se utilizar para las instrucciones de firma y para describir los documentos que se van a firmar.
        `,
                buttons: buttons,
            },
            {
                attachTo: {
                    element: '.step_1500',
                    on: 'auto'
                },
                title: 'TEXTO DEL BOTÓN DE ACCIÓN',
                text: `
        Escriba aquí el TEXTO DEL BOTÓN DE ACCIÓN.  Normalmente será algo similar a "FIRMAR LOS DOCUMENTOS" o "ACEPTAR LOS DOCUMENTOS" aunque puede elegir el texto que mejor se adapte a sus necesidades. 
        `,
                buttons: buttons,
            },
            {
                attachTo: {
                    element: '.step_1600',
                    on: 'auto'
                },
                title: 'PIE DE PAGINA',
                text: `
        Y para finalizar esta sección, escriba el PIE DEL CORREO.  Generalmente se utiliza para mostrar un teléfono de contacto o una persona de contacto.  Igualmente puede personalizarlo a su gusto.
        `,
                buttons: buttons,
            },
            {
                attachTo: {
                    element: '.step_1700',
                    on: 'auto'
                },
                title: 'PREVISUALIZACIÓN',
                text: `
            Puede previsualizar el resultado aproximado de la página de firma aquí.
        `,
                buttons: buttons,
            },
            {
                attachTo: {
                    element: '.step_1800',
                    on: 'auto'
                },
                title: 'SECCIÓN 4: DOCUMENTO PDF',
                text: `
            Puede seleccionar un documento PDF GENÉRICO que luego puede terminar de rellenar con el editor integrado.  Tanto si selecciona uno genérico, como si no lo hace, tendrá la posibidad de cambiarlo durante el envío.
        `,
                buttons: buttons,
            },
            {
                attachTo: {
                    element: '.step_1900',
                    on: 'auto'
                },
                title: 'GUARDAR',
                text: `
            Finalmente, pulse este botón para guardar los cambios.
        `,
                showOn() {
                    return document.querySelector('.step_1900') ? true : false;
                },
                buttons: buttonsLast,
            }

        ]

    }

}