import { Injectable } from '@angular/core';
import { buttonsFirst, buttons, buttonsLast } from '../botones';

@Injectable()
export class StepsStripe {

    constructor(

    ) {
        this.crear();
    }

    crear() {

        return [


            {
                attachTo: {
                    element: '.step_100',
                    on: 'auto'
                },
                title: 'RECARGA DE CRÉDITO',
                text: `
                    En esta opción puede recargar el crédito para utilizar en nuestra plataforma.  Para realizar la recarga, utilizaremos 
                    STRIPE como plataforma de pago.  El importe mínimo de la recarga son 5 Euros.
                `,
                buttons: buttonsFirst,
            },
            {
                attachTo: {
                    element: '.step_200',
                    on: 'auto'
                },
                title: 'PRECIOS',
                text: `
                    Estos son los precios que se aplicarán a los diferentes servicios que existen en nuestra plataforma.
                `,
                buttons: buttons,
            },
            {
                attachTo: {
                    element: '.step_300',
                    on: 'auto'
                },
                title: 'TARJETA DE CRÉDITO',
                text: `
                    En esta sección se debe rellenar con los datos de su tarjeta de crédito dónde quiere que se realice el cargo.  Son tres campos:
                    El número de tarjeta, fecha de caducidad y el código CCV que se encuentra detrás de su tarjeta.

                `,
                buttons: buttons,
            },
            {
                attachTo: {
                    element: '.step_400',
                    on: 'auto'
                },
                title: 'IMPORTE DE LA REGARGA',
                text: `
                    Finalmente seleccione el importe que desea recargar.  Puede utilizar los selectores para subir o bajar el importe.  La cantidad mínima son 5 euros.  
                `,
                buttons: buttons,
            },
            {
                attachTo: {
                    element: '.step_500',
                    on: 'auto'
                },
                title: 'CONTINUAR',
                text: `
                    Finalmentee pulse sobre este botón para completar el pago.  Es posible que se redireccione a la pasarela de pago de su banco para
                    completar la transacción.  
                `,
                buttons: buttonsLast,
            }
        ]

    }

}