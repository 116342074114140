import { ButtonModule } from 'primeng/button';
import { VariablesGlobales } from './services/variablesglobales.service';
import { SailsModule } from './services/angular2-sails/sails.module';
import { BarraSuperiorModule } from './componentes/barra-superior/barra-superior.module';

import { BotonIrAtrasModule } from './componentes/boton-atras/boton-atras.module';

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app.routing';

import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

import { HttpClientModule } from '@angular/common/http';

import { EnviosService } from './services/httpclient/envios.service';
import { PlantillasService } from './services/httpclient/plantillas.service';

import { Angulartics2Module } from 'angulartics2';

import { FabricModule, FabricConfigInterface, FABRIC_CONFIG } from 'ngx-fabric-wrapper';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxSpinnerService } from 'ngx-spinner';


import { ActualizadorService } from './services/actualizador.service';

import { ConfirmationService } from 'primeng/api';

import { RippleModule } from 'primeng/ripple';

import { TourService } from './services/tour-service/tour.service';

import { ErrorHandler } from '@angular/core';
import { ErrorService } from './services/error.service'

import { TourModule } from './services/tour-service/tour.module';
import { BlockUIModule } from 'primeng/blockui';

const DEFAULT_FABRIC_CONFIG: FabricConfigInterface = {
  allowTouchScrolling: true
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [

    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    BarraSuperiorModule,
    BotonIrAtrasModule,
    ToastModule,
    HttpClientModule,
    ButtonModule,
    BlockUIModule,
    RippleModule,
    SailsModule.forRoot(),
    NgxSpinnerModule,
    FabricModule,
    Angulartics2Module.forRoot(),
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),

    TourModule,

  ],
  providers: [
    VariablesGlobales,
    MessageService,
    EnviosService,
    PlantillasService,
    NgxSpinnerService,
    ActualizadorService,
    ConfirmationService,
    {
      provide: FABRIC_CONFIG,
      useValue: DEFAULT_FABRIC_CONFIG
    },
    {
      provide: ErrorHandler,
      useClass: ErrorService,
    },
  ],
  bootstrap: [
    AppComponent
  ]

})
export class AppModule { }
