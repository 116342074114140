import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TourService } from './tour.service';

import { StepsPlantillasListar } from './steps/steps.plantillas-listar';
import { StepsPlantillasCrearEditar } from './steps/steps.plantillas-crear-editar';
import { StepsPerfil } from './steps/steps.perfil';
import { StepsLogin } from './steps/steps.login';
import { StepsInicio } from './steps/steps.inicio';
import { StepsFacturas } from './steps/steps.facturas';
import { StepsContactos } from './steps/steps.contactos';
import { StepsConfiguracion } from './steps/steps.configuracion';
import { StepsEnviosNuevo } from './steps/steps.envios-nuevo';
import { StepsTokens } from './steps/steps.tokens';
import { StepsEnviosConsultar } from './steps/steps.envios-consultar';
import { StepsEnviosVer } from './steps/steps.envios-ver';
import { StepsSellarWeb} from './steps/steps.sellar-web';
import { StepsSellarFichero} from './steps/steps.sellar-fichero';
import { StepsSellarNavegacion } from './steps/steps.sellar-navegacion';
import { StepsSellosDeTiempoConsultar} from './steps/steps.sellos-de-tiempo-consultar';
import { StepsStripe } from './steps/steps.stripe';
import { StepsSoporte } from './steps/steps.soporte';
import { StepsApi } from './steps/steps.api';


@NgModule({
  imports: [
    CommonModule,

  ],
  declarations: [

  ],
  exports: [

  ],
  providers: [
    TourService,

    StepsConfiguracion,
    StepsContactos,
    StepsFacturas,
    StepsInicio,
    StepsLogin,
    StepsPerfil,
    StepsPlantillasCrearEditar,
    StepsPlantillasListar,
    StepsEnviosNuevo,
    StepsTokens,
    StepsEnviosConsultar,
    StepsEnviosVer,
    StepsSellarWeb,
    StepsSellarFichero,
    StepsSellarNavegacion,
    StepsSellosDeTiempoConsultar,
    StepsStripe,
    StepsSoporte,
    StepsApi,
  ]

})

export class TourModule { }
