import { URLBACKEND, API } from './variablesglobales.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment.prod';
import { Injectable, ErrorHandler } from '@angular/core';
import * as StackTrace from 'stacktrace-js';

@Injectable({
    providedIn: 'root'
})
export class ErrorService implements ErrorHandler {

    constructor(
        private httpClient: HttpClient,
        private deviceDetectorService: DeviceDetectorService,
    ) {

    }


    handleError(error: any) {

        try {

            StackTrace.fromError(error).then(stackframes => {

                const stackString = stackframes
                    .splice(0, 50)
                    .map(function (sf) {
                        return sf.toString();
                    }).join('\n');

                let datos = {
                    dispositivo: this.deviceDetectorService.getDeviceInfo(),
                    stack: stackString,
                }

                //  Y lo mostramos por la consola.
                if (environment.production) {
                    this.httpClient.post(URLBACKEND + API + 'administracion/varios/errores-frontend', datos).subscribe(
                        res => {
                            console.log('Error FRONTEND enviado al servidor');
                        },
                        err => {
                            console.log('Error FRONTEND NO enviado al servidor:', err);
                        }
                    );

                }

            });


            //  Y lo mostramos por la consola.
            if (environment.production) {
                console.error('error.service: ', error);
            } else {
                console.error('error.service: ', error);
            }

        } catch (error) {
            console.log('ErrorHandler: ', error);
        }

    }

}