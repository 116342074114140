import { buttonsFirst, buttons, buttonsLast } from '../botones';
import { Injectable } from '@angular/core';

@Injectable()
export class StepsLogin {

    constructor(

    ) {
        this.crear();
    }

    crear() {

        return [

            {
                attachTo: {
                    element: '.step_100',
                },
                title: 'BIENVENIDOS',
                text: ['Bienvenidos a CertiFirm.EU'],
                buttons: buttonsFirst,
            },
            {
                attachTo: {
                    element: '.step_200',
                    on: 'auto'
                },
                title: 'NOMBRE DE USUARIO',
                text: ['Introduzca el nombre de usuario con el que se ha registrado para acceder al PANEL DE CONTROL del sistema.'],
                buttons: buttons,
            },
            {
                attachTo: {
                    element: '.step_300',
                    on: 'auto'
                },
                title: 'CONTRASEÑA',
                text: ['Introduzca la constraseña que eligió durante el proceso de registro.'],
                buttons: buttons,
            },
            {
                attachTo: {
                    element: '.step_400',
                    on: 'bottom'
                },
                title: 'ENTRAR',
                text: ['Finalmente pulse sobre el botón ENTRAR.'],
                buttons: buttons,
            },
            {
                attachTo: {
                    element: '.step_500',
                    on: 'bottom'
                },
                title: 'RECORDAR CONTRASEÑA',
                text: ['En el caso que no recuerde su contraseña, puede recuperarla pulsando aquí.'],
                buttons: buttonsLast,
            }
        ]

    }

}