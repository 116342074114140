import { buttonsFirst, buttons, buttonsLast } from '../botones';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class StepsEnviosVer {

    public panelSeleccionado$: Subject<number> = new Subject<number>();

    constructor(

    ) {
        this.crear();
    }

    crear() {

        return [

            {
                attachTo: {
                    on: 'center'
                },
                title: 'VER ENVÍO',
                text: `
                    En esta opción puede acceder a todos los datos del envío.  
                    Está dividida en 5 pestañas en las que podrá encontrar toda la información al respecto.
                `,
                buttons: buttonsFirst,
            },
            {
                attachTo: {
                    element: '.step_100',
                    on: 'auto'
                },
                title: 'DATOS GENERALES',
                text: `
                    En esta pestaña podrá encontrar la información relativa al envío que realizó, el estado en curso.
                    También puede añadir o modificar la descripción para posteriormente localizarlo con mayor facilidad.
                `,
                buttons: buttons,
            },
            {
                attachTo: {
                    element: '.step_200',
                    on: 'auto'
                },
                title: 'DESCARGA DE DOCUMENTOS',
                text: `
                    Puede descargar los documentos del envío en el caso que esté completado.  
                `,
                buttons: buttons,
                when: {
                    hide: () => {
                        this.panelSeleccionado$.next(1);
                    }
                }
            },
            {
                attachTo: {
                    element: '.step_300',
                    on: 'auto'
                },
                title: 'DETALLES CORREOS ELECTRÓNICOS',
                text: `
                    Puede consultar también el estado de los correos electrónicos o, en el caso de envíos a multiples destinatarios, el estado de de firma y correos de los mismos individualmente.  
                `,
                buttons: buttons,
                when: {
                    hide: () => {
                        this.panelSeleccionado$.next(2);
                    }
                }
            },
            {
                attachTo: {
                    element: '.step_400',
                    on: 'auto'
                },
                title: 'PLANTILLA',
                text: `
                    En esta seccion se muestra el tipo de plantilla utilizada para el envío. Pulsando sobre el botón VER, podrá acceder a ella.
                `,
                buttons: buttons,
                when: {
                    hide: () => {
                        this.panelSeleccionado$.next(3);
                    }
                }
            },
            {
                attachTo: {
                    element: '.step_500',
                    on: 'auto'
                },
                title: 'DOCUMENTO A FIRMAR',
                text: `
                    Aquí puede visualizar el documento enviado para su firma.
                `,
                buttons: buttons,
                when: {
                    hide: () => {
                        this.panelSeleccionado$.next(4);
                    }
                }
            },
            {
                attachTo: {
                    element: '.step_600',
                    on: 'auto'
                },
                title: 'CANCELAR EL PROCESO',
                text: `
                    Finalmente, puede CANCELAR el proceso de envío en el caso que fuese de su interés.
                `,
                buttons: buttonsLast,
            }
        ]

    }

}