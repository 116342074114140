import { VariablesGlobales, URLBACKEND, API  } from './../variablesglobales.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class EnviosService {

  constructor(
    private httpClient: HttpClient,
    public variablesGlobales: VariablesGlobales,
  ) {

  }


  desencriptar(file: File): Observable<any> {

    const formData = new FormData();

    formData.append('documento', file);

    return this.httpClient.post(URLBACKEND + API + 'envios/desencriptar', formData, { withCredentials: true, responseType: 'blob' as 'json' });

  }

  
  enviar(file: File, parametros: any): Observable<any> {

    const formData = new FormData();

    Object.keys(parametros).forEach(
      key => {
        if (key === 'destinos') {
          const temp = parametros['destinos'].join(';');
          formData.append(key, temp);
        } else {
          formData.append(key, parametros[key]);
        }
      }
    );

    formData.append('documento', file);

    return this.httpClient.post(URLBACKEND + API + 'envios/crear', formData, { withCredentials: true, responseType: 'text' });

  }

}
