import { ButtonModule } from 'primeng/button';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BotonIrAtrasComponent } from './boton-atras.component';
import { RippleModule } from 'primeng/ripple';


@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    RippleModule,
  ],
  declarations: [
    BotonIrAtrasComponent
  ],
  exports: [
    BotonIrAtrasComponent
  ]
})
export class BotonIrAtrasModule { }
