import { Injectable } from '@angular/core';
import { buttonsFirst, buttons, buttonsLast } from '../botones';

@Injectable()
export class StepsApi {

    constructor(

    ) {
        this.crear();
    }

    crear() {

        return [

            {
                attachTo: {
                    element: '.step_100',
                    on: 'auto'
                },
                title: 'API',
                text: `
                    En esta página encontrará información sobre como acceder a nuestra API, sus métodos, objetos y ejemlos de uso.
                `,
                buttons: buttonsFirst,
            },
            {
                attachTo: {
                    element: '.step_200',
                    on: 'auto'
                },
                title: 'EJEMPLO',
                text: `
                    En primer lugar, un breve ejemplo de uso, con el que podrá obtener una idea global de como hacer llamadas al sistema.
                `,
                buttons: buttons,
            },
            {
                attachTo: {
                    element: '.step_300',
                    on: 'auto'
                },
                title: 'METODOS',
                text: `
                  En esta sección puede encontrar información sobre los MÉTODOS de nuestra API.
                `,
                buttons: buttons,
            },
            {
                attachTo: {
                    element: '.step_400',
                    on: 'auto'
                },
                title: 'OBJETOS',
                text: `
                    Y finalmente los OBJETOS utilizados en estos métodos.
                `,
                buttons: buttonsLast,
            },
            {
                attachTo: {
                    // element: '.step_400',
                    on: 'auto'
                },
                title: 'SOPORTE',
                text: `
                    No dude en contactar con nosotros para ofrecerle soporte en el caso de querer utilizarla.
                `,
                buttons: buttonsLast,
            }
        ]

    }

}