import { buttonsFirst, buttons, buttonsLast } from '../botones';
import { Injectable } from '@angular/core';

@Injectable()
export class StepsPerfil {

    constructor(

    ) {
        this.crear();
    }

    crear() {

        return [

            {
                attachTo: {
                    element: '.step_100',
                    on: 'auto'
                },
                title: 'PERFIL',
                        text: `
                En esta página puede modificar los datos que aparecerán en los CERTIFICADOS y FACTURAS generados en nuestra plataforma.
        `,
                buttons: buttonsFirst,
            },
            {
                attachTo: {
                    element: '.step_200',
                    on: 'auto'
                },
                title: 'DATOS',
                text: `
                    En esta zona aparecen los datos generales.  Si desea modificar el email o nombre, contacte con el departamento de soporte pulsando Ayuda ⮕ Soporte en el menú superior.
                `,
                buttons: buttons,
            },
            {
                attachTo: {
                    element: '.step_300',
                    on: 'auto'
                },
                title: 'PRECIOS',
                text: `
                    Y en ésta los precios de cada servicio.  Puede obtener más información al respecto en la página web.
                `,
                buttons: buttons,
            },
            {
                attachTo: {
                    element: '.step_400',
                    on: 'auto'
                },
                title: 'DESBLOQUEAR',
                text: `
                    Pulsando aquí podrá editar los campos del formulario.
                `,
                buttons: buttons,
            },
            {
                attachTo: {
                    element: '.step_500',
                    on: 'bottom'
                },
                title: 'GUARDAR',
                text: `
                    Una vez editados pulse aquí para guardarlos.
                `,
                buttons: buttonsLast,
            }
        ]

    }

}