import { Subject } from 'rxjs';
import { buttonsFirst, buttons, buttonsLast } from '../botones';
import { Injectable } from '@angular/core';

interface Paneles {
    hiddenPanel1: boolean,
    hiddenPanel2: boolean,
    hiddenPanel3: boolean,
}

@Injectable()
export class StepsEnviosNuevo {

    public paneles$: Subject<Paneles> = new Subject<Paneles>();
    public mostrarDialogoAnadirDestinatario$: Subject<boolean> = new Subject<boolean>();
    public mostrarDialogoSeleccionarFicheros$: Subject<boolean> = new Subject<boolean>();

    constructor(
    ) {
        this.crear();
    }

    crear() {

        return [

            {
                attachTo: {
                    on: 'center'
                },
                title: 'NUEVO ENVÍO',
                text: `
                Desde esta pantalla puede realizar nuevos envíos de documentos.  
                Para enviar un documento, es necesario:
                <ul>
                <li> Seleccionar una <b>plantilla</b></li>
                <li> Seleccionar un <b>destinatario</b> (dirección de correo electrónico)</li>
                <li> Seleccionar el <b>documento</b> a firmar</li>
                </ul>
                Para el resto de campos se utilizarán los valores por defecto.
            `,
                buttons: buttonsFirst,
            },
            {
                attachTo: {
                    element: '.step_100',
                    on: 'auto'
                },
                title: 'SELECCIÓN DE PLANTILLA',
                text: `
                Seleccione la PLANTILLA a utilizar en el envío.  En ella está diseñado el email de contacto y la página de firma.  
                Si aún no la ha configurado utilice la opción  del menú superior FIRMA ELECTRÓNICA ⮕ PLANTILLAS
            `,
                buttons: buttons,
            },
            
            {
                attachTo: {
                    element: '.step_200',
                    on: 'auto'
                },
                title: 'SELECCIÓN DEL DESTINATARIO',
                text: `
                A continuación seleccione los destinatarios.
            `,
                buttons: buttons,
            },
            {
                attachTo: {
                    element: '.step_300',
                    on: 'auto'
                },
                title: 'BOTÓN +',
                text: `
                    Para añadir uno nuevo, utilice el botón '+'
                 `,
                buttons: buttons,
                when: {
                    hide: () => {
                        this.mostrarDialogoAnadirDestinatario$.next(true);
                    }
                }
            },
            {
                attachTo: {
                    element: '.step_400',
                    on: 'auto',

                },
                title: 'SELECCIÓN DE DESTINATARIOS',
                text: `
                    Se mostrará la siguiente ventana en la que puede añadir los destinatarios que desee y los métodos de autenticación que considere necesarios según el documento a firmar.  Sólo es necesario el email, pero si añade métodos, aumentará las capacidades probatorias del certificado. 

                `,
                buttons: buttons,
                beforeShowPromise: () => new Promise<void>(resolve => setTimeout(() => resolve(), 500)),
            },

            {
                attachTo: {
                    element: '.step_500',
                    on: 'auto'
                },
                title: 'AYUDA PERSONALIZADA',
                text: `
                    Puede obtener más ayuda sobre cada uno de los métodos pulsando en los iconos de ayuda en cada uno de ellos.
            `,
                buttons: buttons,
                when: {
                    hide: () => {
                        this.mostrarDialogoAnadirDestinatario$.next(false);
                    }
                }
            },
            {
                attachTo: {
                    element: '.step_600',
                    on: 'auto'
                },
                title: 'SELECCIÓN DEL DOCUMENTO',
                text: `
                    Si la plantilla tiene un documento predefinido puede utilizar ese mismo.  En el caso que no tenga o que desee cambiarlo, puede utilizar este botón.  
                    Se abrirá una nueva ventana.
            `,
                buttons: buttons,
                when: {
                    hide: () => {
                        this.mostrarDialogoSeleccionarFicheros$.next(true);
                    }

                }
            },
            {
                attachTo: {
                    element: '.step_700',
                    on: 'auto'
                },
                title: 'SELECTOR DE FICHEROS',
                text: `
                    En esta ventana puede añadir o eliminar documentos y ordenarlos según su criterio.
                `,
                buttons: buttons,
                beforeShowPromise: () => new Promise<void>(resolve => setTimeout(() => resolve(), 500)),
                when: {
                    hide: () => {
                        this.mostrarDialogoSeleccionarFicheros$.next(false);
                    }
                }
            },
            {
                attachTo: {
                    element: '.step_800',
                    on: 'auto'
                },
                title: 'CONTINUAR',
                text: `
                    Pulsando sobre el botón continuar, abrirá el EDITOR de DOCUMENTOS PDF, en los que puede añadir texto, imágenes y dibujos.
                    En la parte inferior del editor podrá pulsar el botón continuar para realizar el envío.
                `,
                buttons: buttons,
            },
            {
                attachTo: {
                    element: '.step_900',
                    on: 'auto'
                },
                title: 'EDITOR PDF',
                text: `
                    Éste es el editor de PDF.  Utilice las barras de herramientas para insertar texto, imágenes o dibujar en sus páginas.
                `,
                buttons: buttons,
                when: {
                    show: () => {
                        this.paneles$.next({
                            hiddenPanel1: true,
                            hiddenPanel2: false,
                            hiddenPanel3: true,
                        });
                    },
                    hide: () => {
                        this.paneles$.next({
                            hiddenPanel1: false,
                            hiddenPanel2: true,
                            hiddenPanel3: true,
                        });
                    }
                }
            },
            {
                attachTo: {
                    on: 'center'
                },
                title: 'ENVIAR',
                text: `
                    Una vez finalizada la edición del documento, pulse en ENVIAR para iniciar el proceso de ENTREGA y FIRMA ELECTRÓNICA CERTIFICADA.
            `,
                buttons: buttonsLast,
                beforeShowPromise: () => new Promise<void>(resolve => setTimeout(() => resolve(), 500)),
                when: {
                    show: () => {
                        this.paneles$.next({
                            hiddenPanel1: true,
                            hiddenPanel2: true,
                            hiddenPanel3: false,
                        });
                    },
                }
            },


        ]

    }

    porDefecto(completeOrCancel: string) {

        this.mostrarDialogoAnadirDestinatario$.next(false);

        this.mostrarDialogoSeleccionarFicheros$.next(false);

        this.paneles$.next({
            hiddenPanel1: false,
            hiddenPanel2: true,
            hiddenPanel3: true,
        });


    }

}