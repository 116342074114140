import { combineLatest } from 'rxjs';
import { MessageService } from 'primeng/api';
import { Component } from '@angular/core';
import { VariablesGlobales } from './services/variablesglobales.service';

import { DeviceDetectorService } from 'ngx-device-detector';

import { PrimeNGConfig } from 'primeng/api';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  public mensaje = '';

  bloquearPantalla = false;

  constructor(
    private deviceService: DeviceDetectorService,
    private messageService: MessageService,
    public variablesGlobales: VariablesGlobales,
    private primengConfig: PrimeNGConfig
  ) {

    
    this.variablesGlobales.estadoSocket$.subscribe(
      res => {
         this.bloquearPantalla = !res;
      }
    );

  }


  ngOnInit() {

    this.primengConfig.ripple = true;

  }


  ngAfterViewInit() {

    this.avisoNavegador();

  }


  avisoNavegador() {

    const browserDetectado = this.deviceService.getDeviceInfo().browser;

    if (['Chrome', 'Firefox', 'Safari'].includes(browserDetectado)) {

    } else if (['IE'].includes(browserDetectado)) {

      this.messageService.add({
        severity: 'warn',
        summary: '¡NAVEGADOR NO SOPORTADO!',
        detail: 'Para el correcto funcionamiento de todos los componentes del sistema, recomendamos el uso de Google Chrome, Firefox o Safari actualizado. Internet Explorer está totalmente desaconsejado y no está soportado.',
        life: 20000,
        data: {
          icono: 'ion-md-browsers'
        }
      });

    } else {

      this.messageService.add({
        severity: 'warn',
        summary: '¡NAVEGADOR DESCONOCIDO!',
        detail: 'Para el correcto funcionamiento de todos los componentes del sistema, recomendamos el uso de Google Chrome, Firefox o Safari actualizado, aunque su navegador quizás funcione correctamente.',
        life: 20000,
        data: {
          icono: 'ion-md-browsers'
        }
      });
    }
  }

}
