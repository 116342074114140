import { Injectable } from '@angular/core';
import { buttonsFirst, buttons, buttonsLast } from '../botones';

@Injectable()
export class StepsInicio {

    constructor(

    ) {
        this.crear();
    }

    crear() {

        return [

            {
                attachTo: {
                    element: '.step_000',
                    on: 'auto'
                },
                title: 'AYUDA',
                text: `
            Siempre que lo desee puede pulsar este icono para obtener una breve ayuda de la página en la que se encuentra. <br><br>
            Recuerde también que puede consultar en <a href="https://certifirm.eu" target="_blank" style="text-decoration:none; color: black; font-weight: bold;">nuestra página web</a>,
            la sección de manuales, videos y más documentación.<br><br>
            `             ,
                buttons: buttonsFirst,
            },
            {
                attachTo: {
                    element: '.step_050',
                    on: 'auto'
                },
                title: 'MENU PRINCIPAL',
                text: `
            En la parte superior de la pantalla tiene acceso menú principal. 
            Si está comprimido, en la parte izquierda de la pantalla encontrará un botón para desplegarlo.
            `,
                buttons: buttons,
            },
            {
                attachTo: {
                    element: '.step_200',
                    on: 'top'
                },
                title: 'RESUMEN DE FUNCIONES',
                text: `
            Aquí puede encontrar un pequeño resumen de las diferentes pantallas de las que consta la aplicación. En la actualidad ofrecemos dos servicios de certificación:
            <ul>
            <li><b>Firma Electrónica Certificada</b></li>
            <li><b>Sellos de Tiempo</b></li>
            </ul>
            `,
                buttons: buttons,
            },
            {
                attachTo: {
                    // element: '.step_300',
                    // on: 'auto'
                },
                title: 'FIRMA ELECTRÓNICA / ENTREGA CERTIFICADA',
                text: `
            La FIRMA ELECTRÓNICA con ENTREGA CERTIFICADA es un proceso mediante el cual se puede FIRMAR un documento sin tener firma digital.  Se utiliza para certificar la entrega y firma de documentos.
            `,
                buttons: buttons,
            },
            {
                attachTo: {
                    // element: '.step_300',
                    // on: 'auto'
                },
                title: 'SELLOS DE TIEMPO',
                text: `
            Los SELLOS DE TIEMPO son útiles cuando se quiere demostrar que un determinado fichero digital o página web existía en un determinado instante. Puede certificar páginas web, documentos electrónicos o sesiones de navegación. 
            `,
                buttons: buttons,
            },
            {
                attachTo: {
                    // element: '.step_400',
                    // on: 'auto'
                },
                title: 'MANUALES, VIDEOS y PREGUNTAS FRECUENTES',
                text: `
            Puede ampliar la información en nuestra página web <a href="https://certifirm.eu" target="_blank" style="text-decoration: none; color: black; font-weight: bold;">https://certifirm.eu</a> en las secciones de MANUALES, VIDEOS y PREGUNTAS FRECUENTES.
            `,
                buttons: buttonsLast,
            }
        ]

    }

}