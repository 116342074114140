import { buttonsFirst, buttons, buttonsLast } from '../botones';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';

interface Paneles {
    panel1Colapsado: boolean,
    panel2Colapsado: boolean,
    panel3Colapsado: boolean,
    panel4Colapsado: boolean,
}

@Injectable()
export class StepsSellarWeb {

    public paneles$: Subject<Paneles> = new Subject<Paneles>();

    constructor(

    ) {
        this.crear();
    }

    crear() {

        return [

            {
                attachTo: {
                    on: 'auto'
                },
                title: 'SELLAR WEB',
                text: `
                    Desde esta opción puede realizar una captura de una página web y generar el informe que certifica que existió el contenido en un determinado momento.
                    También se pueden certificar documentos PDF ONLINE.
                `,
                buttons: buttonsFirst,
            },
            {
                attachTo: {
                    on: 'auto'
                },
                title: 'EXTENSIÓN',
                text: `
                    Si desea realizar capturas de páginas que requieren usuario y contraseña puede utilizar nuestra extensión.  Pulse 
                    <a href="https://chrome.google.com/webstore/detail/${environment.idExtension}/publish-accepted?authuser=0&hl=es" target="_blank"
                    style="text-decoration:none; color: black"
                    ><b>AQUÍ</b></a> para descargarla en el caso que no la tenga.  Una vez obtenida, recargue la página para poder utilizarla.
                    <br><br>
                    <b>IMPORTANTE:</b>  No la utilice la extensión si desea certificar páginas web de su propiedad, ya que podría invalidar la prueba.
                `,
                buttons: buttons,
            },
            {
                attachTo: {
                    element: '.step_100',
                    on: 'auto'
                },
                title: 'DIRECCIÓN DE LA PÁGINA WEB (URL) ',
                text: `
                    Escriba aquí o copie y pegue la dirección de la página que desea realizar la captura.
                `,
                buttons: buttons,
            },
            {
                attachTo: {
                    element: '.step_200',
                    on: 'auto'
                },
                title: 'OTROS PARÁMETROS DE CAPTURA',
                text: `
                    Puede cambiar también el IDIOMA con el que quiere que se identique el navegador, así como ANCHO, ALTO y ESCALA de la captura para obtener el resultado deseado.
                `,
                buttons: buttons,
             
            },
            {
                attachTo: {
                    element: '.step_300',
                    on: 'auto'
                },
                title: 'PREVISUALIZAR',
                text: `
                    Pulse el botón para PREVISUALIZAR el resultado.
                `,
                buttons: buttons,
                when: {
                    hide: () => {
                        this.paneles$.next({
                            panel1Colapsado: true,
                            panel2Colapsado: false,
                            panel3Colapsado: true,
                            panel4Colapsado: true,
                        });
                    }
                }
            },
            {
                attachTo: {
                    element: '.step_400',
                    on: 'auto'
                },
                title: 'RESULTADO DE LA PREVISUALIZACIÓN',
                text: `
                    Aquí se mostrará la imagen de la captura que se adjuntará al documento PDF.  Si el resultado es el deseado, pulse en CONTINUAR. 
                    Si quiere repetir el proceso con otras opciones, pulse el botón VOLVER.
                    Si está conforme con el resultado obtenido, puse en CONTINUAR.
                `,
                buttons: buttons,
                when: { 
                    hide: () => {
                        this.paneles$.next({
                            panel1Colapsado: true,
                            panel2Colapsado: true,
                            panel3Colapsado: false,
                            panel4Colapsado: true,
                        });
                    }
                }
            },
            {
                attachTo: {
                    element: '.step_500',
                    on: 'bottom'
                },
                title: 'NOMBRE Y DESCRIPCIÓN',
                text: `
                    Escriba un nombre para identificar posteriormente la captura.  Si lo desea, puede escribir una descripción más amplia por la que luego también podrá buscar. 
                `,
                buttons: buttons,
                when: { 
                    hide: () => {
                        this.paneles$.next({
                            panel1Colapsado: true,
                            panel2Colapsado: true,
                            panel3Colapsado: true,
                            panel4Colapsado: false,
                        });
                    }
                }
            },
            {
                attachTo: {
                    element: '.step_600',
                    on: 'bottom'
                },
                title: 'FINALIZAR',
                text: `
                    Pulse en FINALIZAR para terminar el proceso y emitir el certificado correspondiente.
                `,
                buttons: buttonsLast,
            },

        ]

    }


    porDefecto(completeOrCancel: string) {

        this.paneles$.next({
            panel1Colapsado: false,
            panel2Colapsado: true,
            panel3Colapsado: true,
            panel4Colapsado: true,
        });


    }

}