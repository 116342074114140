import { Injectable } from '@angular/core';
import { buttonsFirst, buttons, buttonsLast } from '../botones';

@Injectable()
export class StepsContactos {

    constructor(

    ) {
        this.crear();
    }

    crear() {

        return [

            {
                attachTo: {
                    element: '.step_100',
                    on: 'auto'
                },
                title: 'CONTACTOS',
                text: `
                    En esta página puede administrar los contactos.  <b>No es necesario almacenar un contacto</b> para poder enviarle un documento.  
                    Sólo si envía documentos repetitivamente a los mismos contactos le será de utilidad tenerlos almacenados aquí para elegirlos en el momento del  envío.
                `,
                buttons: buttonsFirst,
            },
            {
                attachTo: {
                    element: '.step_200',
                    on: 'auto'
                },
                title: 'NUEVO CONTACTO',
                text: `
                    Si quiere crear un nuevo contacto utilice este botón.
                `,
                buttons: buttonsFirst,
            },
            {
                attachTo: {
                    element: '.step_300',
                    on: 'auto'
                },
                title: 'EXPORTAR',
                text: `
                    Puede exportar los registros en formato CSV (Hoja de cálculo) por si desea importarlos en su programa de gestión o realizar cálculos sobre ellos con una hoja de cálculo como Microsoft Excel, Google Sheets, LibreOffice, ... 
                `,
                buttons: buttons,
            },
            {
                attachTo: {
                    element: '.step_400',
                    on: 'auto'
                },
                title: 'QUITAR FILTRO',
                text: `
                    Si existe algún filtro aplicado sobre los registros puede quitarlo pulsando aquí.
                `,
                buttons: buttons,
            },
            {
                attachTo: {
                    element: '.step_500',
                    on: 'auto'
                },
                title: 'COLUMNAS',
                text: `
                    Al pulsar sobre cada columna se ordenarán los registros de forma ascendente o descendente.  También puede reordenar las columnas arrastrándolas a la posición deseada manteniendo pulsado el botón del ratón.
                `,
                buttons: buttons,
            },
            {
                attachTo: {
                    element: '.step_600',
                    on: 'auto'
                },
                title: 'FILTROS',
                text: `
                    Puede filtrar los registros por columnas introduciendo valores en estas casillas.
                `,
                buttons: buttons,
            },


            {
                attachTo: {
                    element: '.p-datatable-tbody',
                    on: 'auto'
                },
                title: 'DATOS',
                text: `
                    En la parte central de la tabla tendrá acceso a los registros.  En la última columna se muestran las acciones que puede realizar sobre cada registro.
                `,
                buttons: buttons,
            },
            {
                attachTo: {
                    element: '.p-paginator',
                    on: 'auto'
                },
                title: 'PAGINADOR',
                text: `
                    Puede cambiar de página utilizando el paginador y el número de registros mostrados en cada página.
                `,
                buttons: buttonsLast,
            },

        ]

    }

}