import { buttonsFirst, buttons, buttonsLast } from '../botones';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

interface Paneles {
    panel1Colapsado: boolean,
    panel2Colapsado: boolean,
    panel3Colapsado: boolean,
}

@Injectable()
export class StepsSellarNavegacion {

    public paneles$: Subject<Paneles> = new Subject<Paneles>();

    constructor(

    ) {
        this.crear();
    }

    crear() {

        return [

            {
                attachTo: {
                    on: 'auto'
                },
                title: 'SELLAR SESIÓN DE NAVEGACIÓN',
                text: `
                    Desde esta opción puede certificar una sessión de navegación de 5 minutos de duración.  Utilizará un navegador incrustado y se grabará en vídeo
                    para que una vez finalizada se certifique su contenido.
                `,
                buttons: buttonsFirst,
            },
            {
                attachTo: {
                    element: '.step_100',
                    on: 'auto'
                },
                title: 'PULSE EN INICIAR',
                text: `
                    Al pulsar sobre este botón se abrirá una nueva ventana con un navegador incrustado.  Si no tiene permitidas las ventanas emergentes, 
                    se le solicitará el permiso correspondiente.
                `,
                buttons: buttons,
                when: { 
                    hide: () => {
                        this.paneles$.next({
                            panel1Colapsado: true,
                            panel2Colapsado: false,
                            panel3Colapsado: true,
                        });
                    }
                }
            },
            {
                attachTo: {
                    element: '.step_200',
                    on: 'auto'
                },
                title: 'PREVISUALIZACIÓN',
                text: `
                    A continuación se mostrará el vídeo con el resultado obtenido.  Si está conforme pulse en CONTINUAR.  Si no, puede volver a comenzar el 
                    proceso pulsando en REINICIAR.
                `,
                buttons: buttons, 
                when: { 
                    hide: () => {
                        this.paneles$.next({
                            panel1Colapsado: true,
                            panel2Colapsado: true,
                            panel3Colapsado: false,
                        });
                    }
                }
            },
            {
                attachTo: {
                    element: '.step_300',
                    on: 'bottom'
                },
                title: 'NOMBRE Y DESCRIPCIÓN',
                text: `
                    Escriba un nombre para identificar posteriormente la grabación.  Si lo desea, puede escribir una descripción más amplia por 
                    la que luego también podrá buscar. 
                `,
                buttons: buttons,
                when: { 
                    hide: () => {
                        this.paneles$.next({
                            panel1Colapsado: true,
                            panel2Colapsado: true,
                            panel3Colapsado: false,
                        });
                    }
                }
            },
            {
                attachTo: {
                    element: '.step_400',
                    on: 'bottom'
                },
                title: 'FINALIZAR',
                text: `
                    Pulse en FINALIZAR para terminar el proceso y emitir el certificado correspondiente.
                `,
                buttons: buttonsLast,
            },

        ]

    }


    porDefecto(completeOrCancel: string) {

        this.paneles$.next({
            panel1Colapsado: false,
            panel2Colapsado: true,
            panel3Colapsado: true,
        });


    }

}