import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BarraSuperiorComponent } from './barra-superior.component';

import { MenubarModule } from 'primeng/menubar';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  imports: [
    CommonModule,
    MenubarModule,
    ButtonModule,
    TooltipModule,

  ],
  declarations: [
    BarraSuperiorComponent,
  ],
  exports: [BarraSuperiorComponent]
})

export class BarraSuperiorModule { }
