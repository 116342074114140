import { ApplicationRef, Injectable, NgZone } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { combineLatest, interval, timer } from 'rxjs';

@Injectable()

export class ActualizadorService {

    constructor(
        private appRef: ApplicationRef,
        private zone: NgZone,
        public updates: SwUpdate
    ) {
        // const intervalo$ = interval(1 * 60 * 60 * 1000);
        const intervalo$ = interval(1 * 60 * 1000);
        const retraso$ = timer(5000);
        const concatenar$ = combineLatest([intervalo$, retraso$]).subscribe(
            res => {
                this.comprobarActualizaciones();
            }
        );

    }

    public comprobarActualizaciones() {
        console.log('CHECKEANDO ACTUALIZACIONES');
        this.updates.checkForUpdate().then().catch(err => console.log('ActualizadorService - actualizador.service.ts'));
    }

}
