import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

const routes: Routes = [

  {
    path: '',
    loadChildren: () => import('./componentes/paginas/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'test',
    loadChildren: () => import('./componentes/paginas/test/test.module').then(m => m.TestModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./componentes/paginas/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'inicio',
    loadChildren: () => import('./componentes/paginas/inicio/inicio.module').then(m => m.InicioModule)
  },
  {
    path: 'perfil',
    loadChildren: () => import('./componentes/paginas/perfil/perfil.module').then(m => m.PerfilModule)
  },
  {
    path: 'configuracion',
    loadChildren: () => import('./componentes/paginas/configuracion/configuracion.module').then(m => m.ConfiguracionModule)
  },
  {
    path: 'facturas',
    loadChildren: () => import('./componentes/paginas/facturas/facturas.module').then(m => m.FacturasModule)
  },
  {
    path: 'tokens',
    loadChildren: () => import('./componentes/paginas/tokens/tokens.module').then(m => m.TokensModule)
  },
  {
    path: 'contactos',
    loadChildren: () => import('./componentes/paginas/contactos/contactos.module').then(m => m.ContactosModule)
  },
  {
    path: 'plantillas-listar',
    loadChildren: () => import('./componentes/paginas/plantillas/plantillas-listar/plantillas-listar.module').then(m => m.PlantillasListarModule)
  },
  {
    path: 'plantillas-crear-editar',
    loadChildren: () => import('./componentes/paginas/plantillas/plantillas-crear-editar/plantillas-crear-editar.module').then(m => m.PlantillasCrearEditarModule)
  },
  {
    path: 'envios-nuevo',
    loadChildren: () => import('./componentes/paginas/envios/envios-nuevo/envios-nuevo.module').then(m => m.EnviosNuevoModule)
  },
  {
    path: 'envios-nuevo/:idPlantillaRecibida',
    loadChildren: () => import('./componentes/paginas/envios/envios-nuevo/envios-nuevo.module').then(m => m.EnviosNuevoModule)
  },
  {
    path: 'envios-consultar',
    loadChildren: () => import('./componentes/paginas/envios/envios-consultar/envios-consultar.module').then(m => m.EnviosConsultarModule)
  },
  {
    path: 'envios-ver/:tipoRecibido/:idRecibido',
    loadChildren: () => import('./componentes/paginas/envios/envios-ver/envios-ver.module').then(m => m.EnviosVerModule)
  },
  {
    path: 'sellar-web',
    loadChildren: () => import('./componentes/paginas/sellos-de-tiempo/sellar-web/sellar-web.module').then(m => m.SellarWebModule)
  },
  {
    path: 'sellar-fichero',
    loadChildren: () => import('./componentes/paginas/sellos-de-tiempo/sellar-fichero/sellar-fichero.module').then(m => m.SellarFicheroModule)
  },
  {
    path: 'sellar-navegacion',
    loadChildren: () => import('./componentes/paginas/sellos-de-tiempo/sellar-navegacion/sellar-navegacion.module').then(m => m.SellarNavegacionModule)
  },
  {
    path: 'sellodetiempo-consultar',
    loadChildren: () => import('./componentes/paginas/sellos-de-tiempo/consultar/sellodetiempo-consultar.module').then(m => m.SelloDeTiempoConsultarModule)
  },
  {
    path: 'stripe',
    loadChildren: () => import('./componentes/paginas/stripe/stripe.module').then(m => m.StripeModule)
  },
  {
    path: 'soporte',
    loadChildren: () => import('./componentes/paginas/soporte/soporte.module').then(m => m.SoporteModule)
  },
  {
    path: 'administracion-usuarios',
    loadChildren: () => import('./componentes/paginas/administracion/usuarios/usuarios.module').then(m => m.AdministracionUsuariosModule)
  },
  {
    path: 'formulario-alta',
    loadChildren: () => import('./componentes/paginas/administracion/formularioalta/formularioalta.module').then(m => m.FormularioAltaModule)
  },
  {
    path: 'cambio-contrasena1',
    loadChildren: () => import('./componentes/paginas/administracion/cambio-contrasena1/cambio-contrasena1.module').then(m => m.CambioContrasena1Module)
  },
  {
    path: 'cambio-contrasena2',
    loadChildren: () => import('./componentes/paginas/administracion/cambio-contrasena2/cambio-contrasena2.module').then(m => m.CambioContrasena2Module)
  },
  {
    path: 'api',
    loadChildren: () => import('./componentes/paginas/api/api.module').then(m => m.ApiModule)
  },
  {
    path: 'editor-pdf',
    loadChildren: () => import('./componentes/editor-pdf/editor-pdf.module').then(m => m.EditorPdfModule)
  },
  {
    path: 'mostrar-documento',
    loadChildren: () => import('./componentes/paginas/mostrar-documento/mostrar-documento.module').then(m => m.MostrarDocumentoModule)
  },
  {
    path: 'mostrar-documento/:uid_envio',
    loadChildren: () => import('./componentes/paginas/mostrar-documento/mostrar-documento.module').then(m => m.MostrarDocumentoModule)
  },
  {
    path: '404',
    loadChildren: () => import('./componentes/paginas/error404/error404.module').then(m => m.Error404Module)
  },
  {
    path: '**',
    redirectTo: '/404'
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
