import { Injectable } from '@angular/core';
import { buttonsFirst, buttons, buttonsLast } from '../botones';

@Injectable()
export class StepsConfiguracion {

    constructor(

    ) {
        this.crear();
    }

    crear() {

        return [

            {
                attachTo: {
                    element: '.step_100',
                    on: 'auto'
                },
                title: 'CONFIGURACIÓN',
                text: `
            En esta página puede cambiar las opciones predeterminadas de los ENVÍOS y el tema visual del PANEL de CONTROL.
        `,
                buttons: buttonsFirst,
            },
            {
                attachTo: {
                    element: '.step_200',
                    on: 'auto'
                },
                title: 'OPCIONES DE LOS ENVÍOS',
                text: `
            En esta puede seleccionar las opciones por defecto utilizadas en los nuevos envíos.  Podrá cambiarlas posteriormente durante el proceso de envío.
        `,
                buttons: buttons,
            },
            {
                attachTo: {
                    element: '.step_300',
                    on: 'auto'
                },
                title: 'RECIBIR DOCUMENTOS POR EMAIL',
                text: `
            Si se marca esta opción, recibirá en su correo en un archivo comprimido la información completa del envío.
            Siempre podrá descargarlo igualmente desde la sección de envíos, pulsando en VER DETALLES
        `,
                buttons: buttons,
            },
            {
                attachTo: {
                    element: '.step_400',
                    on: 'auto'
                },
                title: 'RECIBIR NOTIFICACIONES POR EMAIL',
                text: `
            Si se marca esta opción, los cambios de estado del envío le serán notificados por email.
        `,
                buttons: buttons,
            },
            {
                attachTo: {
                    element: '.step_500',
                    on: 'auto'
                },
                title: 'ENVIAR COPIA AL DESTINATARIO POR EMAIL',
                text: `
            Si se marca esta opción, se enviará un certificado RESUMIDO al firmante.
        `,
                buttons: buttons,
            },
            {
                attachTo: {
                    element: '.step_600',
                    on: 'auto'
                },
                title: 'SELECCIÓN DEL TEMA',
                text: `
            Seleccione el tema por defecto de la aplicación.  Puede alternar entre el tema CLARO y el OSCURO.
                    `,
                buttons: buttons,
            },
            {
                attachTo: {
                    element: '.step_800',
                    on: 'auto'
                },
                title: 'GUARDAR',
                text: `
            Una vez seleccionadas las opciones que desee pulse aquí para guardarlas.
        `,
                buttons: buttonsLast,
            },

        ]

    }

}