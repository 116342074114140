// console.log("%cThis is a green text", "color:green");

import { Injectable, NgZone } from '@angular/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { SailsService } from './angular2-sails/sails.service';
import { Router } from '@angular/router';

import { environment } from '../../environments/environment';

import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';

// SERVIDOR
export const DEBUG = true;
export const URLFRONTEND = environment.urlFrontEnd;
export const URLBACKEND = environment.urlBackend;
export const API = '/api/v1/';
export const VERSION = '1';

export const ES_CALENDARIO = {
  firstDayOfWeek: 1,
  dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
  dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
  dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
  monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio',
    'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
  monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
  today: 'Hoy',
  clear: 'Borrar'
};

@Injectable()
export class VariablesGlobales {

  public datosUsuario: any;
  public datosUsuario$ = new Subject<any>();

  public estadoSocket$ = new Subject<boolean>();

  public cambiarTema$ = new Subject<boolean>();
  public logotipo = './assets/logo300.png';

  constructor(
    private router: Router,
    private sailsService: SailsService,
    private ngZone: NgZone,
    public angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
  ) {

    angulartics2GoogleAnalytics.startTracking();

    const opts = {
      url: URLBACKEND,
      transports: ['polling','websocket'],
      reconnection: true
    };

    
    this.sailsService.connect(opts).subscribe(
      res => {
        this.ngZone.run(
          () => {
            this.estadoSocket$.next(res.connected);
          }
        );
        console.log('this.sailsService.connect: ', res);
      },
      err => {
        console.log('ERROR this.sailsService.connect: ', err);
      }
    );
    

    this.datosUsuario$.subscribe(
      res => {
        console.log('VARIABLES GLOBALES - DATOS USUARIO MODIFICADOS: ', res);
        this.datosUsuario = res;
        if (res) this.cambiarTema$.next(this.datosUsuario.conf_tema_claro);
      }
    );

    this.cambiarTema$.pipe(distinctUntilChanged()).subscribe(
      claro => {
        let tema;
        if (claro) {
          tema = 'saga-blue';
          this.logotipo = './assets/logo300.png';
        } else {
          tema = 'arya-blue';
          this.logotipo = './assets/logo300blanco.png';
        }
        let themeElement = document.getElementById('theme-link');
        themeElement.setAttribute('href', 'assets/themes/' + tema + '/theme.css');
      }
    )

    this.suscribir_cambiosdatosUsuario();

  }


  suscribir_cambiosdatosUsuario() {
    this.sailsService.on('user').subscribe(
      res => {
        console.log('EVENTO SAILS: ', res);
        this.solicitardatosUsuario();
      },
      err => {
        console.log('ERROR: ' + JSON.stringify(err));
      }
    );
  }


  solicitardatosUsuario() {
    this.sailsService.get('/api/v1/cuenta/datosusuario').subscribe(
      res => {
        this.datosUsuario$.next(res.data);
      },
      err => {
        console.log('ERROR: ', err);
      }
    );
  }


  ping_logueado() {
    console.log('PING');
    this.sailsService.get('/api/v1/cuenta/ping').subscribe(
      res => {
        console.log('PONG');
        if (this.datosUsuario === undefined) {
          this.solicitardatosUsuario();
          if (environment.production) {
            this.router.navigate(['inicio']);
          } else {
            // DESARROLLO
            this.router.navigate(['sellodetiempo-consultar']);
          }
        }
      },
      err => {
        console.log('ERROR: ', err);
        this.router.navigate(['login']);
      }
    );
  }

}
