import { buttonsFirst, buttons, buttonsLast } from '../botones';
import { Injectable } from '@angular/core';

@Injectable()
export class StepsPlantillasListar {

    constructor(

    ) {
        this.crear();
    }

    crear() {
        return [

            {
                attachTo: {
                    element: '.step_100',
                    on: 'auto'
                },
                title: 'LISTA DE PLANTILLAS',
                text: `
            En esta página puede administrar las plantillas que utilizará para los envíos.  Una vez utilizada no se puede eliminar pero puede ocultarlas si lo desea.
        `,
                buttons: buttonsFirst,
            },
            {
                attachTo: {
                    element: '.step_200',
                    on: 'auto'
                },
                title: 'NUEVA PLANTILLA',
                text: `
            Si quiere crear un nueva plantilla desde cero pulsando en este botón.  
            Le recomendamos hacerlo duplicando una existente, utilizando el botón "DUPLICAR" de la "Plantilla de Ejemplo".
        `,
                buttons: buttonsFirst,
            },
            {
                attachTo: {
                    element: '.step_300',
                    on: 'auto'
                },
                title: 'EXPORTAR',
                text: `
            Puede exportar los registros en formato CSV (Hoja de cálculo) por si desea importarlos en su programa de gestión o realizar cálculos sobre ellos con una hoja de cálculo como Microsoft Excel, Google Sheets, LibreOffice, ... 
        `,
                buttons: buttons,
            },
            {
                attachTo: {
                    element: '.step_400',
                    on: 'auto'
                },
                title: 'QUITAR FILTRO',
                text: `
            Si existe algún filtro aplicado sobre los registros puede quitarlo pulsando aquí.
        `,
                buttons: buttons,
            },
            {
                attachTo: {
                    element: '.step_500',
                    on: 'auto'
                },
                title: 'COLUMNAS',
                text: `
            Al pulsar sobre cada columna se ordenarán los registros de forma ascendente o descendente.  También puede reordenar las columnas arrastrándolas a la posición deseada manteniendo pulsado el botón del ratón.
        `,
                buttons: buttons,
            },
            {
                attachTo: {
                    element: '.step_600',
                    on: 'auto'
                },
                title: 'FILTROS',
                text: `
            Puede filtrar los registros por columnas introduciendo valores en estas casillas.
        `,
                buttons: buttons,
            },
            {
                attachTo: {
                    element: '.step_700',
                    on: 'auto'
                },
                title: 'MOSTRAR PLANTILLAS OCULTAS',
                text: `
            Si tiene plantillas ocultas puede mostrarlas activando este selector.
        `,
                canClickTarget: true,
                buttons: buttons,
            },
            {
                attachTo: {
                    element: '.p-datatable-tbody',
                    on: 'auto'
                },
                title: 'DATOS',
                text: `
            En la parte central de la tabla tendrá acceso a los registros.  En la última columna se muestran las acciones que puede realizar sobre cada registro.
        `,
                buttons: buttons,
            },
            {
                attachTo: {
                    element: '.p-paginator',
                    on: 'auto'
                },
                title: 'PAGINADOR',
                text: `
            Puede cambiar de página utilizando el paginador y el número de registros mostrados en cada página.
        `,
                buttons: buttonsLast,
            },

        ]

    }

}