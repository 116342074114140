import { buttonsFirst, buttons, buttonsLast } from '../botones';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

interface Paneles {
    panel1Colapsado: boolean,
    panel2Colapsado: boolean,
    panel3Colapsado: boolean,
}

@Injectable()
export class StepsSellarFichero {

    public paneles$: Subject<Paneles> = new Subject<Paneles>();

    constructor(

    ) {
        this.crear();
    }

    crear() {

        return [

            {
                attachTo: {
                    on: 'auto'
                },
                title: 'SELLAR FICHERO',
                text: `
                    Desde esta opción puede certificar la existencia de un determinado documento digital o electrónico
                    en el momento de la certificación así como la posesión del mismo.
                `,
                buttons: buttonsFirst,
            },
            {
                attachTo: {
                    element: '.step_100',
                    on: 'auto'
                },
                title: 'SELECCIONAR EL FICHERO',
                text: `
                    Seleccione el documento que desea certificar.  El documento no se transmitirá a nuestros servidores.  Se calculará su HASH y se enviará
                    al nuestros servidores para la emisión del correspondiente certificado.
                `,
                buttons: buttons,
            },
            {
                attachTo: {
                    element: '.step_200',
                    on: 'auto'
                },
                title: 'CONTINUAR',
                text: `
                    Pulse en el botón de CONTINUAR.
                `,
                buttons: buttons,  when: { 
                    hide: () => {
                        this.paneles$.next({
                            panel1Colapsado: true,
                            panel2Colapsado: false,
                            panel3Colapsado: true,
                        });
                    }
                }
            },
            {
                attachTo: {
                    element: '.step_300',
                    on: 'bottom'
                },
                title: 'NOMBRE Y DESCRIPCIÓN',
                text: `
                    Escriba un nombre para identificar posteriormente la captura.  Si lo desea, puede escribir una descripción más amplia por 
                    la que luego también podrá buscar. 
                `,
                buttons: buttons,
                when: { 
                    hide: () => {
                        this.paneles$.next({
                            panel1Colapsado: true,
                            panel2Colapsado: true,
                            panel3Colapsado: false,
                        });
                    }
                }
            },
            {
                attachTo: {
                    element: '.step_400',
                    on: 'bottom'
                },
                title: 'FINALIZAR',
                text: `
                    Pulse en FINALIZAR para terminar el proceso y emitir el certificado correspondiente.
                `,
                buttons: buttonsLast,
            },

        ]

    }


    porDefecto(completeOrCancel: string) {

        this.paneles$.next({
            panel1Colapsado: false,
            panel2Colapsado: true,
            panel3Colapsado: true,
        });


    }

}