<ngx-spinner bdOpacity=1 bdColor="rgb(255, 255, 255, 0.8)" size="large" color="#006b8f" type="line-scale-party"
    [fullScreen]="true">
</ngx-spinner>

<ngx-spinner name="cargandoDocumento" bdOpacity=1 bdColor="rgb(255, 255, 255, 1)" size="large" color="#006b8f"
    type="timer" [fullScreen]="true">
    <p style="color: #006b8f; font-size: 20px; padding-top: 20px; text-align: center;">Cargando el documento...</p>
</ngx-spinner>

<p-blockUI [blocked]="bloquearPantalla"></p-blockUI>

<!--
<p-blockUI [blocked]="pantallaBloqueada">
  <div
    style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); text-align: center; background-color: rgba(255, 255, 255, 0.5); border: 1px solid rgba(255, 255, 255, 0.0.8); padding: 30px;">
    <i class="fa fa-cog fa-spin fa-5x fa-fw"></i>
    <h1>Procesando... Por favor, espere...</h1>
  </div>
</p-blockUI>
-->

<app-barra-superior></app-barra-superior>

<router-outlet></router-outlet>

<!-- TOAST MODAL -->
<p-toast #toastmodal sticky="true" position="center">
    <ng-template let-message pTemplate="message">
        <div style="text-align: center; width: 100%;">
            <i class="{{message.data.icono}}" style="font-size: 3em"></i>
            <h3>{{ message.summary }}</h3>
            <p>{{ message.detail }}</p>
            <button pButton pRipple style="width: 100%" label="OK" [ngClass]="{
                'p-button-danger': message.severity === 'error',
                'p-button-success': message.severity === 'success'
              }" (click)="messageService.clear()"></button>
        </div>
    </ng-template>
</p-toast>

<!--
  this.messageService.clear();
  this.messageService.add({
    severity: 'error',
    summary: '¡ERROR!',
    detail: 'Ha ocurrido un error.',
    life: 10000,
    data: {
      icono: 'pi pi-times-circle'
    }
  });
  -->

<!-- TOAST NO MODAL - SUPERIOR DERECHA-->
<p-toast #toastnomodal key="no_modal" life="2000" sticky="false" position="top-right" closable="true">
    <ng-template let-message pTemplate="message">
        <div style="text-align: center; width: 100%;">
            <i class="{{message.data.icono}}" style="font-size: 3em"></i>
            <h3>{{ message.summary }}</h3>
            <p>{{ message.detail }}</p>
        </div>
    </ng-template>
</p-toast>

<!--
  this.messageService.clear();
  this.messageService.add({
    key: 'no_modal',
    severity: 'info',
    summary: null,
    detail: 'Hay un filtro aplicado.  Pulsa en QUITAR FILTRO si quieres eliminarlo.',
    life: 3000,
    data: {
      icono: 'fa fa-info-circle'
    }
  });
  -->