export const buttonsFirst = [
    {
        classes: 'shepherd-boton-salir',
        text: 'Salir',
        type: 'cancel'
    },
    {
        classes: 'shepherd-boton-siguiente',
        text: 'Siguiente ⇨',
        type: 'next'
    }
];

export const buttons = [
    {
        classes: 'shepherd-boton-salir',
        text: 'Salir',
        type: 'cancel'
    },
    /*
    {
        text: '⇦ Atrás',
        type: 'back'
    },
    */
    {
        classes: 'shepherd-boton-siguiente',
        text: 'Siguiente ⇨',
        type: 'next'
    }
];

export const buttonsLast = [
    /*
    {
        text: '⇦ Atrás',
        type: 'back'
    }, 
    */
    {
        classes: 'shepherd-boton-salir',
        text: 'Finalizar',
        type: 'cancel'
    },
];