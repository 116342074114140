import { VariablesGlobales, URLBACKEND, API } from './../variablesglobales.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class PlantillasService {

  constructor(
    private _http: HttpClient,
    public variablesGlobales: VariablesGlobales,
  ) {

  }

  crear(file: File, parametros: any): Observable<any> {

    const formData = new FormData();

    Object.keys(parametros).forEach(
      key => {
        formData.append(key, parametros[key]);
      }
    );

    formData.append('documento', file);

    return this._http.post(URLBACKEND + API + 'plantillas/crear', formData, { withCredentials: true, responseType: 'text' });

  }


  editar(file: File, parametros: any): Observable<any> {

    const formData = new FormData();

    Object.keys(parametros).forEach(
      key => {
        formData.append(key, parametros[key]);
      }
    );

    formData.append('documento', file);

    return this._http.post(URLBACKEND + API + 'plantillas/editar', formData, { withCredentials: true, responseType: 'text' });

  }

}
