import { TourService } from '../../services/tour-service/tour.service';
import { VariablesGlobales } from '../../services/variablesglobales.service';
import { Component, OnInit, ViewChild } from '@angular/core';

import { SailsService } from '../../services/angular2-sails/sails.service';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';

import { NgxSpinnerService } from 'ngx-spinner';

import findIndex from 'lodash-es/findIndex';

@Component({
  selector: 'app-barra-superior',
  templateUrl: './barra-superior.component.html',
  styleUrls: ['./barra-superior.component.css']
})
export class BarraSuperiorComponent implements OnInit {
  @ViewChild('barrasup', { static: false }) barrasup;

  items: any[];


  constructor(
    private messageService: MessageService,
    private sailsService: SailsService,
    public router: Router,
    private spinner: NgxSpinnerService,
    private tourService: TourService,
    public variablesGlobales: VariablesGlobales,
  ) {

  }


  ngOnInit() {

    this.iniciar_items_menu();

    this.variablesGlobales.datosUsuario$.subscribe(

      res => {

        if (this.variablesGlobales.datosUsuario) {

          this.iniciar_items_menu();

          const index = findIndex(this.items,
            (o) => {
              return o.marcador === 'CREDITO';
            }
          );

          this.items[index].label = this.variablesGlobales.datosUsuario.credito_disponible.toFixed(2) + '€';

        }

        if (this.variablesGlobales.datosUsuario && this.variablesGlobales.datosUsuario.isSuperAdmin) {

          this.items.push(
            {
              label: 'Administrador',
              icon: 'fa fa-superpowers',
              items: [
                {
                  label: 'Lista de usuarios',
                  icon: 'fa fa-users',
                  routerLink: ['administracion-usuarios'],
                  title: 'Lista de usuarios',
                },
              ]

            },
          );
        }

      }
    );

  }


  cambioUrlSpinner(evento) {
    const elementoActual = this.router.url.slice(1, this.router.url.length);
    if (!evento.item.routerLink.includes(elementoActual)) {
      this.spinner.show()
    }
  }


  iniciar_items_menu() {

    this.items = [
      {
        label: 'Inicio',
        icon: 'pi pi-home',
        routerLink: ['inicio'],
        title: 'Resumen de las funciones de la aplicación.',
        command: (e: Event) => this.cambioUrlSpinner(e)
      },
      {
        label: 'Mis Datos',
        icon: 'pi pi-list',
        title: 'Consulta los datos de tu perfil.',
        items: [
          {
            label: 'Perfil',
            icon: 'pi pi-user',
            routerLink: ['perfil'],
            title: 'Datos de su perfil.',
            command: (e: Event) => this.cambioUrlSpinner(e)
          },
          {
            label: 'Configuración',
            icon: 'pi pi-cog',
            routerLink: ['configuracion'],
            title: 'Configuración y personalización del panel de control.',
            command: (e: Event) => this.cambioUrlSpinner(e)
          },
          {
            label: 'Facturas',
            icon: 'pi pi-file',
            routerLink: ['facturas'],
            title: 'Lista de sus facturas.',
            command: (e: Event) => this.cambioUrlSpinner(e)
          },
          {
            label: 'Tokens',
            icon: 'pi pi-id-card',
            routerLink: ['tokens'],
            title: 'Administra los tokens para usar con la API REST.',
            command: (e: Event) => this.cambioUrlSpinner(e)
          },
        ]
      },

      // ENTREGA CERTIFICADA
      {
        label: 'FIRMA ELECTRÓNICA',
        icon: 'pi pi-file-pdf',
        title: 'Entrega Certificada y Firma Electrónicia.',
        items: [
          {
            label: 'Contactos',
            icon: 'pi pi-users',
            routerLink: ['contactos'],
            title: 'Crea, modifica y añade contactos.',
            command: (e: Event) => this.cambioUrlSpinner(e)
          },
          {
            label: 'Plantillas',
            icon: 'pi pi-file',
            routerLink: ['plantillas-listar'],
            title: 'Crear, editar y modificar sus plantillas de envío de documentos.',
            command: (e: Event) => this.cambioUrlSpinner(e)
          },
          {
            label: 'Nuevo Envío',
            icon: 'pi pi-envelope',
            routerLink: ['envios-nuevo'],
            title: 'Enviar un documento para firmar.',
            command: (e: Event) => this.cambioUrlSpinner(e)
          },
          {
            label: 'Consultar Envíos',
            icon: 'pi pi-list',
            routerLink: ['envios-consultar'],
            title: 'Consulta los envíos realizados.',
            command: (e: Event) => this.cambioUrlSpinner(e)
          },

        ]
      },

      // SELLO DE TIEMPO
      {
        label: 'SELLOS DE TIEMPO',
        icon: 'pi pi-clock',
        title: 'Sello de tiempo para archivos digitales',
        items: [
          {
            label: 'Sellar/Certificar Web',
            icon: 'pi pi-desktop',
            routerLink: ['sellar-web'],
            title: 'Sella/Certifica una web y sus contenidos emitiendo un certificado.',
            command: (e: Event) => this.cambioUrlSpinner(e)
          },
          {
            label: 'Sellar/Certificar Fichero',
            icon: 'pi pi-file-pdf',
            routerLink: ['sellar-fichero'],
            title: 'Sella/Certifica un fichero digital emitiendo un certificado.',
            command: (e: Event) => this.cambioUrlSpinner(e)
          },
          {
            label: 'Sellar/Certificar Sesión de Navegación',
            icon: 'pi pi-globe',
            routerLink: ['sellar-navegacion'],
            title: 'Sella/Certifica una sesión navegación emitiendo un certificado.',
            command: (e: Event) => this.cambioUrlSpinner(e)
          },
          {
            label: 'Consultar Sellos/Certificados',
            icon: 'pi pi-list',
            routerLink: ['sellodetiempo-consultar'],
            title: 'Consulta los sellos/certificados de tiempo solicitados.',
            command: (e: Event) => this.cambioUrlSpinner(e)
          },
        ]
      },

      {
        label: 'Recargar',
        icon: 'pi pi-money-bill',
        routerLink: ['stripe'],
        title: 'Recargar créditos.',
        command: (e: Event) => this.cambioUrlSpinner(e)
      },

      // OPCIONES
      {
        label: 'Ayuda',
        icon: 'pi pi-info-circle',
        title: 'Ayuda',
        items: [
          {
            label: 'Manual',
            icon: 'pi pi-info-circle',
            title: 'Ver el manual de ayuda online.',
            command: (event) => {
              const url = 'https://certifirm.eu/web/guia-del-panel-de-control/';
              window.open(url, '_blank');
            }
          },
          {
            label: 'Soporte',
            icon: 'pi pi-ticket',
            routerLink: ['soporte'],
            title: 'Formulario de contacto.',
            command: (e: Event) => this.cambioUrlSpinner(e)
          },
          {
            label: 'API',
            icon: 'fa fa-code',
            routerLink: ['api'],
            title: 'Documentación de la API REST.',
            command: (e: Event) => this.cambioUrlSpinner(e)
          }

          /*
          {
            label: 'Webhooks',
            icon: 'fa fa-arrow-circle-o-left',
            routerLink: ['opciones-webhooks'],
            title: 'Administra los Webhooks.',
            command: (event) => {
              this.iniciar_items_menu();
            },
          },
          */

        ]
      },
      {
        marcador: 'CREDITO',
        label: this.variablesGlobales.datosUsuario && this.variablesGlobales.datosUsuario.credito_disponible.toFixed(2) + '€',
        icon: 'pi pi-check',
        title: 'Crédito disponible en este momento.',
      },
      {
        label: 'Salir',
        icon: 'pi pi-sign-out',
        routerLink: ['login'],
        command: (event) => {
          this.logout();
        },
      },

    ];

  }


  logout() {

    this.sailsService.get('/api/v1/logout').subscribe(
      res => {
        console.log('OK: ', res);
        this.variablesGlobales.ping_logueado();

        this.messageService.clear();
        this.messageService.add({
          key: 'no_modal',
          severity: 'info',
          summary: null,
          detail: 'Te has deslogueado correctamente.',
          life: 3000,
          data: {
            icono: 'pi pi-info-circle'
          }
        });

      },

      err => {
        console.log('ERROR: ', err);
        this.variablesGlobales.ping_logueado();

        this.messageService.clear();
        this.messageService.add({
          severity: 'error',
          summary: '¡ERROR!',
          detail: 'Ha ocurrido un error: ' + err.data,
          life: 10000,
          data: {
            icono: 'pi pi-times'
          }
        });

      },

      () => {
        this.variablesGlobales.datosUsuario$.next(undefined);
      }

    );
  }


  btnAyuda() {

    this.tourService.mostrarTour();

  }


}


