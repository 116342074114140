import { Injectable } from '@angular/core';
import { buttonsFirst, buttons, buttonsLast } from '../botones';

@Injectable()
export class StepsSoporte {

    constructor(

    ) {
        this.crear();
    }

    crear() {

        return [

            {
                attachTo: {
                    element: '.step_100',
                    on: 'auto'
                },
                title: 'SOPORTE',
                text: `
                    Puede contactar con nuestro departamente de soporte utilizando el siguiente formulario,
                    o escribir directamente a la dirección de correo electrónico 
                    <a href="mailto:soporte@certifirm.eu" style="text-decoration: none; color: black">soporte@certifirm.eu</a>
                `,
                buttons: buttonsFirst,
            },
            {
                attachTo: {
                    element: '.step_200',
                    on: 'auto'
                },
                title: 'TIPO',
                text: `
                    Especifique el tipo de mensaje que desea enviar: Sugerencia, cambio de datos, error, ...
            `,
                buttons: buttons,
            },
            {
                attachTo: {
                    element: '.step_300',
                    on: 'auto'
                },
                title: 'TÍTULO',
                text: `
                    Escriba un título breve.
                    `,
                buttons: buttons,
            },
            {
                attachTo: {
                    element: '.step_400',
                    on: 'auto'
                },
                title: 'MENSAJE',
                text: `
                    Escriba el cuerpo del mensaje lo más detalladamente posible.
                    `,
                buttons: buttons,
            },
            {
                attachTo: {
                    element: '.step_500',
                    on: 'auto'
                },
                title: 'ENVIAR',
                text: `
                    Finalmente pulse sobre este botón para enviar el formulario.  Le responderemos lo antes posible.
                `,
                buttons: buttonsLast,
            }
        ]

    }

}